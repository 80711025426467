@import 'shared/assets/style/variables';
.block{
    position: relative;
}
.error {
    color: red;
}
.input{
    width: 100%;
    background-color: transparent;
    border: 1px solid $main-white;
    border-radius: 6px;
    padding: 8px 10px;
    color: $main-white;
    margin-bottom: 10px;
}
.label{
    color: $main-white;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
}
.icon{
    position: absolute;
    right: 10px;
    top: 38px;
    display: block;
    width: 24px;
    height: 24px;
    line-height: 26px;
    text-align: center;
    z-index: 1;
    float: right;
    cursor: pointer;
}
.input_date::-webkit-calendar-picker-indicator {
    background-image: url('date.svg');
    width: 20px;
    height: 20px;
}
