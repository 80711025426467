@import 'shared/assets/style/variables';
body{
    background-color: #202020 !important;
    font-family: 'Inter', sans-serif !important;
}
main{
    width: 100%;
}
h1{
    font-size: 60px;
    color: #fff;
}
.container{
    // max-width: 1660px !important;
    max-width: 100% !important;
    padding: 0 20px 0 120px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 130px;
    // margin-top: 40px;
}
.overlay{
    display: none;
    &-active{
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0, .45);
        z-index: 500;
    }
}
.col-lg-12{
    position: relative;
}
@media (max-width: 576px) {
    .container{
        padding: 0 20px 0 120px !important;
    }
}
@media (max-width: 480px) {
    .wrapper{
        margin-left: 0;
    }
    .container{
        padding: 0 20px !important;
        margin-top: 24px;
    }
}
