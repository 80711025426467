.block{
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 991px) {
  .block{
    flex-direction: column;
    width: 100%;
  }
}