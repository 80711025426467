@import 'shared/assets/style/variables';

.block{
  position: absolute;
  transform: translateX(50%);
  right: 50%;
  top: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img{
  width: 300px;
}
.text{
  text-align: center;
  color: $red;
  font-size: 30px;
}
.link {
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  color: $main-white;
  border: none;
  border-radius: 6px;
  margin-top: 30px;
  background-color: $blue;
  padding: 10px 20px;
  text-decoration: none;
  &:hover{
    color: $main-white;
    opacity: 0.6;
    transition: all .3s;
  }
}
