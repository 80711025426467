.exit{
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    display: flex;
    margin-top: 15px;
    cursor: pointer;
    &:before{
        content: url('./exit.svg');
        margin-right: 5px;
    }

}


.non-visible{
    display: none;

}

@media (max-width: 480px) {
    .visible{
        display: none;
    }
    .non-visible{
        display: flex;
        position: absolute;
        bottom: 10%;
        font-size: 14px;
        font-weight: 400;
    }
}
