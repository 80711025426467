@import 'shared/assets/style/variables';

.text{
    font-size: 12px;
    color: $main-white;
    font-weight: 600;
    &:hover{
        color: $main-white
    }
}
.block{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    &:hover{
        opacity: .6;
    }
    &:not(:last-child) {
        margin-bottom: 40px;
    }
}

@media (max-width: 480px) {
   .block{
       flex-direction: row;
       svg{
           width: 24px;
           height: 24px;
           margin-right: 10px;
       }
   }
    .text{
        font-size: 14px;
    }
}