@import 'shared/assets/style/variables';
@import 'shared/assets/style/mixins';
.item{
  padding: 17.5px 0;
  width: 100%;
  .row{
    display: flex !important;
    align-items: center !important;
  }
}
.name_block{
  display: flex;
  align-items: center;
}
.title{
  color: $main-white;
}
.block{
  display: flex;
}
.info{
  display: flex;
  flex-direction: column;
}
.avatar{
  @include thumbnail;
  margin-right: 10px;
  background-color: transparent;
  img{
    @include avatar;
  }
}
.thumbnail{
  @include thumbnail;
  margin-right: 10px;
}
.text{
  font-size: 16px;
  font-weight: 400;
  color: $main-white;
}
.quote{
  font-size: 14px;
  font-weight: 400;
  color: $main-white;
  font-style: italic;
}
.position{
  font-size: 12px;
  font-weight: 400;
  color: $main-gray;
}
.offline, .online, .leave {
  display: flex;
  margin-right: 0;
  margin-left: 10px;
  align-items: center;
  &:before{
    content: '';
    display: block;
    border-radius: 50%;
    margin-right: 10px;
    width: 10px;
    height: 10px;
    background-color: $red;
  }
}
.offline, {
  &:before{
    background-color: $main-gray;
  }
}
.online, {
  &:before{
    background-color: $main-green;
  }
}
.leave, {
  &:before{
    background-color: $red;
  }
}
@media (max-width: 576px) {
  .offline, .online, .leave{
    display: flex;
    margin-right: 0;
    margin-left: 10px;
  }
}

