@import 'shared/assets/style/mixins';
@import 'shared/assets/style/variables';

.form, .form_active{
  @include wrapper;
  @include modal;
}
.form {
  display: none;
}
.form_active{
  @include form_active;
  top: 20%;
}
.title{
  @include title;
}
.change{
  color: $blue;
  text-decoration: underline;
  text-decoration-color: $blue;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

