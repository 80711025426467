$main-white: #E2E2E2;
$main-black: #282828;
$main-green: #5BD348;
$main-gray: #929292;

$second-black: #1F1F1F;
$second-gray: #484848;

$blue: #2373D0;
$azure: #8BC0FF;
$red: #DE4C4A;
