@import 'shared/assets/style/variables';
.arrow {
  display: none;
}
@media (max-width: 1200px) {
  .arrow{
    display: block;
    width: 12px;
    height: 12px;
    border-top: 1px solid $main-gray;
    border-right: 1px solid $main-gray;
    transform: rotate(45deg);
  }
}
