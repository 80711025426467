.wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.button_wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}


@media (max-width: 767px) {
  .button_wrapper {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .wrapper{
    height: 80vh;
  }
}
