@import 'shared/assets/style/variables';
@import 'shared/assets/style/mixins';

.wrapper{
  display: none;
}
.wrapper_active{
  @include wrapper;
  position: fixed;
  z-index: 501;
  right: 50%;
  transform: translateX(50%);
  width: 30em;
  top: 20%;
}
.info{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.block{
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.name_block{
  display: flex;
  justify-content: center;
  align-items: center;
}
.name{
  font-size: 16px;
  font-weight: 400;
  color: $main-white;
}
.offline, .online, .leave {
  display: flex;
  align-items: center;
  &:before{
    content: '';
    display: block;
    border-radius: 50%;
    margin-left: 10px;
    width: 10px;
    height: 10px;
    background-color: $red;
  }
}
.offline, {
  &:before{
    background-color: $main-gray;
  }
}
.online, {
  &:before{
    background-color: $main-green;
  }
}
.leave, {
  &:before{
    background-color: $red;
  }
}
@media (max-width: 767px) {
  .wrapper_active{
    width: 30em;
    max-width: 90%;
  }
}
