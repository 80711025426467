@import 'shared/assets/style/variables';

.time {
  color: $main-white;
  font-size: 48px;
  font-weight: 600;
  position: absolute;
  right: 0;
  top: 0;
}
@media (max-width: 480px) {
  .time{
    display: none;
  }
}