@import 'shared/assets/style/variables';
.block{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}
.img{
  width: 100%;
  max-width: 300px;
}
.text{
  color: $main-white;
  font-size: 22px;
  margin-top: 20px;
  text-align: center;
}

@media (max-width: 767px) {
  .block{
    margin-bottom: 20px;
  }
}