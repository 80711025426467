@import 'shared/assets/style/variables';
.close {
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 501;
  width: 18px;
  height: 18px;
  cursor: pointer;

}
.close::before,
.close::after {
  content: '';
  position: absolute;
  //top: 10px;
  display: block;
  width: 18px;
  height: 2px;
  background: $main-gray;
}

.close::before {
  transform: rotate(45deg);
}

.close::after {
  transform: rotate(-45deg);
}
