.wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  position: relative;
}
.button_wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}


@media (max-width: 767px) {
  .button_wrapper {
    flex-direction: column;
  }
}
