@import 'shared/assets/style/variables';
.quote{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.title{
  color: $main-gray;
  font-size: 16px;
  font-weight: 600;
}
.info{
  color: $main-white;
  font-size: 16px;
  font-weight: 400;
  word-break: break-all;
  font-style: italic;
}
