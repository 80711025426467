@import 'shared/assets/style/variables';
@import 'shared/assets/style/mixins';
.form_active{
  @include wrapper;
  @include form_active;
  max-width: 600px;
  top: 30%;
  width: 100%;
  padding: 30px 40px;
}
.title{
  @include title;
}
textarea{
  height: 150px;
}
