@import 'shared/assets/style/variables';
@import 'shared/assets/style/mixins';
.avatar{
  @include thumbnail;
  margin-right: 10px;
  background-color: transparent;
  img{
    @include avatar;
  }
}
.thumbnail{
  @include thumbnail;
  margin-right: 10px;
}
.avatar, .thumbnail {
  width: 60px;
  height: 60px;
}
