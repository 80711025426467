@import 'shared/assets/style/variables';

.wrapper{
    width: 100%;
    height: 100%;
    background-color: $main-black;
    padding: 30px 40px;
    position: relative;
    border-radius: 6px;
}
.title{
    font-size: 16px;
    font-weight: 600;
    color: $main-gray;
}
.titles{
    padding-bottom: 10.5px;
    border-bottom: 1px solid rgba(146, 146, 146, 0.3);
}
@media (max-width: 991px) {
    .wrapper{
        padding: 15px;
    }
}
.item{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(146, 146, 146, 0.3);
}
