@import 'shared/assets/style/variables.scss';

.error{
  display: none;
}
.error_active{
  display: block;
  color: $red;
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
}

