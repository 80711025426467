@import 'shared/assets/style/variables';
@import 'shared/assets/style/mixins';
.form, .form_active{
  @include wrapper;
}
.form{
  display: none;
}
.form_active{
  @include form_active;
  top: 30%;
  max-width: 600px;
  width: 100%;
  padding: 30px 40px;
}
.title{
  @include title;
}
.button {
  @include bigButton;
  background-color: $blue;
  color: $main-white;
  margin-right: 20px;
}

@media (max-width: 767px) {
  .button{
    margin: 0;

  }
}
@media (max-width: 480px) {
  .button{
    width: 90%;
  }
}
