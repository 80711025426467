@import 'shared/assets/style/variables';
.error {
  color: red;
}
.input{
  width: 100%;
  background-color: transparent;
  border: 1px solid $main-white;
  border-radius: 6px;
  padding: 8px 10px;
  color: $main-white;
  margin-bottom: 10px;
}
.label{
  color: $main-white;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
}
option {
  background-color: $main-black;
}


