@import 'shared/assets/style/variables';
@import 'shared/assets/style/mixins';
.outer{
    background: #282828;
    height: 100%;
    width: 100%;
    padding: 20px;
    border-radius: 6px;
    button{
        width: 100%;
    }
}
.wrapper{
    @include wrapper;
}

.block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:not(:last-child) {
        margin-bottom: 30px;
    }
}


