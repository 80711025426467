@import 'shared/assets/style/variables';

@mixin wrapper {
  background: $main-black;
  padding: 20px;
  border-radius: 6px;
  button{
    width: 100%;
  }
}
@mixin form_active {
  display: block;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  z-index: 501;
  @media (max-width: 991px) {
      max-width: 90%;
      width: 90%;
  }
}

@mixin title{
  text-align: center;
  color: $main-white;
  font-size: 20px;
}
@mixin modal {
  @media (max-width: 767px) {
    width: 90%;
  }
}
@mixin bigButton {
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 6px;
  width: 400px;
  height: 70px;
  margin-top: 30px;
}

@mixin button_blue {
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  color: $main-white;
  background-color: $blue;
  border: none;
  border-radius: 6px;
  margin-top: 30px;
}
@mixin thumbnail {
  background-color: #AE0001;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  color: $main-white;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  text-decoration: none;
}
@mixin avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
