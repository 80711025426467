@import 'shared/assets/style/variables';
.block{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.title{
  color: $main-gray;
  font-size: 16px;
  font-weight: 600;
}
.info{
  color: $main-white;
  font-size: 16px;
  font-weight: 400;
}
