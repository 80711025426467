@import 'shared/assets/style/variables';
.header{
    width: 100%;
    background-color: $main-black;
    max-height: 100px;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 23;
}

.burger {
    display: none;
}
@media (max-width: 768px) {
    .title{
        font-size: 20px;
    }
}
@media (max-width: 480px) {
    .header{
        justify-content: space-between;
        height: 75px;
        position: static;
    }
    .logo{
        height: 100%;
        img{
            height: 100%;
        }
    }
    .title{
        display: none;
    }
    .burger{
        display: block;
        position: relative;
        width: 18px;
        height: 12px;
        cursor: pointer;
        z-index: 502;
        margin-right: 10px;
        &:before, &:after{
            content: '';
            background: $main-gray;
            position: absolute;
            width: 100%;
            height: 2px;
            left: 0;
            transition: all .3s;

        }
        &:before{
            top: 0;
        }
        &::after{
            bottom: 0;
        }
        span{
            background-color:  $main-gray;
            position: absolute;
            width: 100%;
            height: 2px;
            left: 0;
            top: 5px;
            transition: all .3s;

        }
        &-active{
            &::before{
                transform: rotate(45deg);
                top: 0px;
                transition: all .3s;
            }
            &::after{
                transform: rotate(-45deg);
                top: 0px;
                transition: all .3s;
            }
            span{
                transform: scale(0);
                transition: all .3s;
            }
        }
    }
}
