@import 'shared/assets/style/variables';
@import 'shared/assets/style/mixins';
.block{
  @include wrapper;
  margin-bottom: 20px;
}
.title, .subtitle{
  color: $main-white;
  margin-bottom: 10px;
}
.title{
  font-size: 18px;
  font-weight: 600;

}
.subtitle{
  display: block;
  font-size: 16px;
  font-weight: 400;
}
.select{
  background-color: transparent;
  color: $main-white;
  border: none;
  margin-bottom: 20px;
  option{
    background-color: $main-black;
    border: none;
  }
}

.error{
  display: none;
}
.error_active{
  display: block;
  color: $red;
  font-size: 14px;
}
