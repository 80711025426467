@import 'shared/assets/style/variables';
@import 'shared/assets/style/mixins';
.nav{
    top: 100px;
    position: fixed;
    width: 100px;
    height: 100vh;
    background-color: $main-black;
    display: block !important;
    &_profile{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 50%;
        transform: translateX(50%);
        bottom: 20%;
        .info{
            text-align: center;
        }
        .exit{
            font-size: 12px;
            font-weight: 600;
            color: $main-white;
            display: flex;
            align-items: center;
            margin-top: 15px;
            cursor: pointer;
        }
    }
    &_links{
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 40px;
    }
}

.name{
    font-size: 12px;
    font-weight: 600;
    color: $main-white;
    text-decoration: none;

}
.avatar {
    @include thumbnail;
    background-color: transparent;
    img{
        @include avatar;
    }
}
.workplace{
    display: none;
    &_color{
        color: green;
    }
}
.thumbnail{
    @include thumbnail;
}
.name, .thumbnail {
    &:hover{
        color: $main-white;
        opacity: .6;
        transition: all .3s;
    }
}
@media (max-width: 480px) {
    .nav{
        height: 100%;
        width: 300px;
        position: fixed;
        right: -200%;
        transition: all .2s;
        top: 0;
        z-index: 22;
        display: flex !important;
        flex-direction: column-reverse;
        justify-content: flex-end;
        padding: 0 20px !important;
        align-items: flex-start;
        z-index: 501;
        &-active{
            right: 0;
            transition: all .3s;

        }
        &_profile{
            margin-top: 40px;
            position: static;
            right: 0;
            transform: translateX(0);
            bottom: 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            .info{
                text-align: initial;
                margin-left: 10px;
            }
            .exit-right{
                display: none;
            }
            .exit-left{
                position: absolute;
                bottom: 10%;
                font-size: 14px;
                font-weight: 400;
            }
            .workplace{
                display: block;
                font-size: 12px;
                font-weight: 400;
                color: $main-white;
                margin-top: 10px;
                &-green{
                    color: $main-green;
                }
            }
        }
        ul{
            align-items: flex-start;
            li{
                width: 100%;
                a{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    position: relative;
                    width: 100%;

                    &:before{
                        margin-right: 10px;
                        margin-bottom: 0;
                    }
                }
            }
        }

    }
}
