@import 'shared/assets/style/variables';
.button {
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    color: $main-white;
    border: none;
    border-radius: 6px;
    margin-top: 30px;
}
.blue{
    background-color: $blue;
    color: $main-white;
}
.azure{
    background-color: $azure;
    color: $blue;
}
