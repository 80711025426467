@import 'shared/assets/style/variables';
@import 'shared/assets/style/mixins';
.name{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  span{
    color: $main-white;
    font-size: 16px;
    font-weight: 600;
  }

}
.avatar{
  @include thumbnail;
  cursor: pointer;
  background-color: transparent;
  width: 60px;
  height: 60px;
  &:hover{
    opacity: 0.6;
    background-image: url('pencil.svg');
    background-repeat: no-repeat;
    background-position: center;
    transition: all .2s;
    background-color: transparent;
  }
  img{
    @include avatar;
    &:hover{
      opacity: 0.6;
      background-image: url('pencil.svg');
      background-repeat: no-repeat;
      background-position: center;
      transition: all .2s;
    }
  }
}
.thumbnail{
  @include thumbnail;
  cursor: pointer;
  &:hover{
    opacity: 0.6;
    background-image: url('pencil.svg');
    background-repeat: no-repeat;
    background-position: center;
    transition: all .2s;
  }
}

.form, .form_active{
  @include wrapper;
  @include modal;
}
.form{
  display: none;
}
.form_active{
  @include form_active;
  top: 30%;
}
.title{
  @include title;
}
