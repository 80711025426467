@import 'shared/assets/style/variables';

.title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  h2{
    font-size: 30px;
    font-weight: 600;
    color: $main-white;
    padding-left: 30px
  };
}
.workplace{
  padding-right: 30px;
  color: $main-white;
  font-size:14px;
  font-weight: 400;
  span{
    font-size: 14px;
    font-weight: 600;
    color: $main-green;
  }
}
@media (max-width: 576px) {
  .title {
    h2{
      font-size: 22px;
    }
  }
  .workplace{
    display: none;
  }
}
@media (max-width: 480px) {
  .title{
    h2{
      display: none;
    }
  }
}
