@import 'shared/assets/style/variables';
@import 'shared/assets/style/mixins';

.form, .form_active{
  @include wrapper;
}
.form{
  display: none;
}
.form_active{
  @include form_active;
  top: 10%;
  max-width: 600px;
  padding: 30px 40px;
}
.title{
  @include title;
}
.option{
  background-color: $main-black;
}
.button {
  @include bigButton;
  background-color: $main-black;
  color: $main-white;
}

@media (max-width: 480px) {
  .button{
    width: 90%;
  }
}
