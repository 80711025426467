.overlay{
    display: none;
    &_active{
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0, .45);
        z-index: 500;
    }
}