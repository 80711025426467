@import 'shared/assets/style/variables';

.button {
  width: 400px;
  height: 70px;
  font-size: 16px;
  font-weight: 600;
  color: $main-white;
  border: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:not(:last-child) {
    margin-right: 20px;
  }
}
.blue{
  background-color: $blue;
  color: $main-white;
  &_opacity{
    opacity: 0.5;
  }
}
.azure{
  -webkit-text-fill-color: $azure;
  background-color: $azure;
  color: $blue;
}
.dark{
  background-color: $main-black;
  color: $main-white;
}
.green {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #5BD348;
  color: $main-white;

}
.office, .weekend, .leave, .home{
  &:before{
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
}
.home {
  &:before{
    content: url('home.svg');
  }
}
.office {
  &:before{
    content: url('office.svg');
  }
}
.weekend {
  &:before{
    content: url('weekend.svg');
  }
}
.leave {
  &:before{
    content: url('leave.svg');
  }
}
@media (max-width: 991px) {
  .button{
    width: 80%;
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
}
@media  (max-width: 576px) {
  .button{
    width: 100%;
  }
}

