@import 'shared/assets/style/variables';
@import 'shared/assets/style/mixins';
.modal{
  display: none;
}
.modal_active{
  @include form_active;
  @include wrapper;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 30%;
  padding: 30px 40px;
}
.img{
  width: 100%;
  max-width: 300px;
}
.text{
  color: $main-white;
  font-size: 22px;
}
@media (max-width: 480px) {
  .modal_active{
    width: 70%;
  }
}